import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import RichTextEditor from 'html-verify-react-rte';
import {isEmptyHtmlEntity, isObjectEmpty} from "../utils/HelpfulFunction";
import swal from 'sweetalert2';

class SetUpRulesAndRegs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesAndRegsText: RichTextEditor.createEmptyValue(),
            howToPlayText: RichTextEditor.createEmptyValue(),
            rulesPopUpText: RichTextEditor.createEmptyValue(),
            rulesShowInAppPopUpText: RichTextEditor.createEmptyValue(),
            didNotCheckRulesAndRegsBody2: RichTextEditor.createEmptyValue(),
            secondaryMandatoryCheckboxText: RichTextEditor.createEmptyValue(),
            rulesPopUpHeader: "",
            rulesShowInAppPopUpHeader: "",
            rulesInAppButtonText: "",
            howToPlayLink: "",
            tenantRules: null,
            loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        this.howToPlayLinkRef = base.bindToState(`tenantRules/howToPlayLink`, {
            context: this,
            state: 'howToPlayLink',
        });
        this.tenantRulesRef = base.listenTo('tenantRules', {
            context: this,
            then(data) {
                this.setState(prevState => {
                    const newState = { ...prevState };

                    // Check for advanced conditions
                    const isAdvanced = data.howToPlayText && !isEmptyHtmlEntity(data.howToPlayText)
                        || data.howToPlayLink
                        || data.turnOnSecondMandatoryCheckbox
                        || data.rulesPopUpText
                        || data.rulesShowInApp
                        || data.rulesAndRegsText;

                    if (isAdvanced) {
                        newState.advanced = true;
                    }

                    if (data.supportText && !isEmptyHtmlEntity(data.supportText)) {
                        newState.supportText = RichTextEditor.createValueFromString(data.supportText, 'html');
                    }
                    if (data.howToPlayText && !isEmptyHtmlEntity(data.howToPlayText)) {
                        newState.howToPlayText = RichTextEditor.createValueFromString(data.howToPlayText, 'html');
                    }
                    if (data.rulesPopUpText) {
                        newState.rulesPopUpText = RichTextEditor.createValueFromString(data.rulesPopUpText, 'html');
                    }
                    if (data.rulesShowInAppPopUpText) {
                        newState.rulesShowInAppPopUpText = RichTextEditor.createValueFromString(data.rulesShowInAppPopUpText, 'html');
                    }
                    if (data.rulesShowInApp) {
                        newState.rulesShowInApp = true;
                    }
                    if (data.rulesAndRegsText) {
                        newState.rulesAndRegsText = RichTextEditor.createValueFromString(data.rulesAndRegsText, 'html');
                    }
                    if(data.turnOnSecondMandatoryCheckbox){
                        newState.turnOnSecondMandatoryCheckbox = data.turnOnSecondMandatoryCheckbox;
                    }
                    if(data.didNotCheckRulesAndRegsHeader2){
                        newState.didNotCheckRulesAndRegsHeader2 = data.didNotCheckRulesAndRegsHeader2;
                    }
                    if(data.didNotCheckRulesAndRegsBody2){
                        newState.didNotCheckRulesAndRegsBody2 = RichTextEditor.createValueFromString(data.didNotCheckRulesAndRegsBody2, 'html');
                    }
                    if(data.secondaryMandatoryCheckboxText){
                        newState.secondaryMandatoryCheckboxText = RichTextEditor.createValueFromString(data.secondaryMandatoryCheckboxText, 'html')
                    }

                    // Return the new state object
                    return newState;
                });
            }
        });
        this.rulesPopUpHeaderRef = base.bindToState(`tenantRules/rulesPopUpHeader`, {
            context: this,
            state: 'rulesPopUpHeader',
        });
        this.rulesInAppButtonTextRef = base.bindToState(`tenantRules/rulesInAppButtonText`, {
            context: this,
            state: 'rulesInAppButtonText',
        });
        this.rulesShowInAppPopUpHeaderRef = base.bindToState(`tenantRules/rulesShowInAppPopUpHeader`, {
            context: this,
            state: 'rulesShowInAppPopUpHeader',
        });
        this.supportHeaderRef = base.bindToState(`tenantRules/supportHeader`, {
            context: this,
            state: 'supportHeader',
        });
        this.setState({
            loading: false
        })
    }

    componentWillUnmount() {
        base.removeBinding(this.howToPlayLinkRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.rulesPopUpHeaderRef);
        base.removeBinding(this.rulesShowInAppPopUpHeaderRef);
        base.removeBinding(this.supportHeaderRef);
        base.removeBinding(this.rulesInAppButtonTextRef);
    }

    handleSubmit(event) {
        event.preventDefault();
        let rulesAndRegsText = this.state.rulesAndRegsText.toString('html');
        let rulesPopUpHeader = this.state.rulesPopUpHeader;
        let didNotCheckRulesAndRegsHeader2 = this.state.didNotCheckRulesAndRegsHeader2 || '';
        let didNotCheckRulesAndRegsBody2 = this.state.didNotCheckRulesAndRegsBody2.toString('html');
        let secondaryMandatoryCheckboxText = this.state.secondaryMandatoryCheckboxText.toString('html');
        let turnOnSecondMandatoryCheckbox = this.state.turnOnSecondMandatoryCheckbox || false;
        let howToPlayLink = this.state.howToPlayLink;
        let rulesPopUpText = this.state.rulesPopUpText.toString('html');
        let howToPlayText = this.state.howToPlayText.toString('html');
        let rulesShowInAppPopUpText = this.state.rulesShowInAppPopUpText.toString('html');
        let rulesShowInAppPopUpHeader = this.state.rulesShowInAppPopUpHeader;
        let supportHeader = this.state.supportHeader || "";
        let supportText = this.state.supportText || "";
        if(supportText){
            supportText = supportText.toString('html');
        }
        let rulesInAppButtonText = this.state.rulesInAppButtonText;
        if(rulesInAppButtonText && rulesInAppButtonText.length > 18){
            swal({
                title: "Input Error",
                text: "Button Text Cannot Be Longer Then 18 Characters",
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        let rulesShowInApp = this.state.rulesShowInApp || false;
        if(isEmptyHtmlEntity(howToPlayText)){
            howToPlayText = "";
        }
        if(isEmptyHtmlEntity(rulesPopUpText)){
            rulesPopUpText = "";
        }
        if(isEmptyHtmlEntity(rulesShowInAppPopUpText)){
            rulesShowInAppPopUpText = "";
        }
        if(isEmptyHtmlEntity(rulesAndRegsText)){
            rulesAndRegsText = "";
        }
        if(isEmptyHtmlEntity(didNotCheckRulesAndRegsBody2)){
            didNotCheckRulesAndRegsBody2 = "";
        }
        if(isEmptyHtmlEntity(secondaryMandatoryCheckboxText)){
            secondaryMandatoryCheckboxText = "";
        }
        let updateRulesObject = {
            "rulesAndRegsText": rulesAndRegsText, "howToPlayLink": howToPlayLink,
            "howToPlayText": howToPlayText, "rulesPopUpText": rulesPopUpText, "rulesPopUpHeader":rulesPopUpHeader,
            "rulesShowInAppPopUpText": rulesShowInAppPopUpText, "rulesShowInAppPopUpHeader": rulesShowInAppPopUpHeader,
            "rulesInAppButtonText": rulesInAppButtonText, "rulesShowInApp": rulesShowInApp, "supportText": supportText,
            "supportHeader": supportHeader, secondaryMandatoryCheckboxText, didNotCheckRulesAndRegsBody2,
            turnOnSecondMandatoryCheckbox, didNotCheckRulesAndRegsHeader2
        }
        this.setState({loading:true})
        const vm = this;
        base.post("tenantRules/", {
            data: updateRulesObject,
            then(err){
              vm.setState({loading:false})
              if(!err){
                swal({
                    title: 'Rules and Regs Updated!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                  })
              } else {
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                  });
                console.log(err)
              }
            }
        })
    }

    handleRichTextChange = (name, text) => {
        this.setState({ [name]:  text});
    }

    handleChange (evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        let rulesAndRegsText = this.state.rulesAndRegsText || RichTextEditor.createEmptyValue();
        let turnOnSecondMandatoryCheckbox = this.state.turnOnSecondMandatoryCheckbox || false;
        let rulesPopUpHeader = this.state.rulesPopUpHeader;
        let rulesPopUpText = this.state.rulesPopUpText;
        let rulesInAppButtonText = this.state.rulesInAppButtonText;
        let rulesShowInAppPopUpHeader = this.state.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = this.state.rulesShowInAppPopUpText;
        let howToPlayLink = this.state.howToPlayLink;
        let howToPlayText = this.state.howToPlayText;
        let secondaryMandatoryCheckboxText = this.state.secondaryMandatoryCheckboxText || RichTextEditor.createEmptyValue();
        let didNotCheckRulesAndRegsBody2 = this.state.didNotCheckRulesAndRegsBody2 || RichTextEditor.createEmptyValue();
        let didNotCheckRulesAndRegsHeader2 = this.state.didNotCheckRulesAndRegsHeader2;
        let supportHeader = this.state.supportHeader;
        let supportText = this.state.supportText || RichTextEditor.createEmptyValue();
        if(isObjectEmpty(rulesPopUpText)){
            rulesPopUpText = ""
        }
        if(isObjectEmpty(rulesAndRegsText)){
            rulesAndRegsText = ""
        }
        if(isObjectEmpty(howToPlayLink)){
            howToPlayLink = ""
        }
        if(isObjectEmpty(rulesPopUpHeader)){
            rulesPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpHeader)){
            rulesShowInAppPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpText)){
            rulesShowInAppPopUpText = ""
        }
        if(isObjectEmpty(rulesInAppButtonText)){
            rulesInAppButtonText = ""
        }
        if(isObjectEmpty(supportHeader)){
            supportHeader = ""
        }
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        if(!isMlbApp){
            toolbarConfig.display.push('LINK_BUTTONS')
        }
        const advanced = this.state.advanced || isMlbApp || false;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="container-out" style={{width:'45%', float:'left'}}>
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-game-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <div className="form-group">
                          <label htmlFor="rulesAndRegsText">Rules And Regs Text</label>
                          <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear AFTER the mandatory confirm rules and regs button on the login form<br/><strong>Example:</strong> Welcome! To play, please confirm that you agree with the rules and regs</p>
                          <RichTextEditor id="rulesAndRegsText" name="rulesAndRegsText" toolbarConfig={toolbarConfig} value={rulesAndRegsText} onChange={(text) => this.handleRichTextChange('rulesAndRegsText', text)}/>
                          {/*<input type="text" id="rulesAndRegsText" name="rulesAndRegsText" className="form-control" value={rulesAndRegsText} onChange={this.handleChange}/>*/}
                      </div>
                      {!isMlbApp &&
                          <div className="form-group">
                              <label htmlFor="showAdvancedSettings">Advanced</label>
                              <br/>
                              <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                          </div>
                      }
                      {advanced &&
                          <>
                            {!isMlbApp &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="howToPlayLink">How To Play Link (Optional)</label>
                                        <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                                        <input id="howToPlayLink" name="howToPlayLink" type="url" className="form-control" value={howToPlayLink} onChange={this.handleChange} placeholder="https://ourgameplay.com"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="howToPlayText">How To Play Text (replaces link if filled)</label>
                                        <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                                        <RichTextEditor id="howToPlayText" name="howToPlayText" toolbarConfig={toolbarConfig} value={howToPlayText} onChange={(text)=>this.handleRichTextChange("howToPlayText",text)}/>
                                    </div>
                                </>
                            }
                              <div className="form-group">
                                  <label htmlFor="rulesPopUpHeader">Rules & Regs Pop Up Header (Optional)</label>
                                  <p className="text-muted2" style={{fontSize:'10px'}}>The header of the in-app pop up containing rules</p>
                                  <input id="rulesPopUpHeader" name="rulesPopUpHeader" type="text" className="form-control" value={rulesPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="rulesPopUpText">Rules & Regs Pop Up Text</label>
                                  <p className="text-muted2" style={{fontSize:'10px'}}>The text of the in-app pop up. When this is filled in it will replace the rules and regs link and the text here will show up in a pop up.</p>
                                  <RichTextEditor id="rulesPopUpText" name="rulesPopUpText" toolbarConfig={toolbarConfig} value={rulesPopUpText} onChange={(text)=>this.handleRichTextChange("rulesPopUpText",text)}/>
                              </div>
                            {!isMlbApp &&
                              <div className="form-group">
                                  <label htmlFor="rulesShowInApp">Show Rules And Regs After Sign Up Screen</label>
                                  <br/>
                                  <input type="checkbox" checked={this.state.rulesShowInApp} id="rulesShowInApp" name="rulesShowInApp" onChange={this.handleChange}/>
                              </div>
                            }
                              {this.state.rulesShowInApp &&
                                  <>
                                      <div className="form-group">
                                          <label htmlFor="rulesInAppButtonText">Button Text</label>
                                          <p className="text-muted2" style={{fontSize:'10px'}}>The text of the button that links to Rules & Regs</p>
                                          <input id="rulesInAppButtonText" name="rulesInAppButtonText" type="text" className="form-control" value={rulesInAppButtonText} onChange={this.handleChange} placeholder="Rules & Regs"/>
                                      </div>
                                      <div className="form-group">
                                          <label htmlFor="rulesShowInAppPopUpHeader">Pop Up After Sign Up (Optional)</label>
                                          <p className="text-muted2" style={{fontSize:'10px'}}>The header of the in-app pop up</p>
                                          <input id="rulesShowInAppPopUpHeader" name="rulesShowInAppPopUpHeader" type="text" className="form-control" value={rulesShowInAppPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs"/>
                                      </div>
                                      <div className="form-group">
                                          <label htmlFor="rulesShowInAppPopUpText">Rules & Regs Pop Up Text</label>
                                          <p className="text-muted2" style={{fontSize:'10px'}}>The body text of the pop up</p>
                                          <RichTextEditor id="rulesShowInAppPopUpText" name="rulesShowInAppPopUpText" toolbarConfig={toolbarConfig} value={rulesShowInAppPopUpText} onChange={(text)=>this.handleRichTextChange("rulesShowInAppPopUpText",text)}/>
                                      </div>
                                  </>
                              }
                              {isMlbApp &&
                                  <>
                                      <div className="form-group">
                                          <label htmlFor="supportHeader">Support Header</label>
                                          <p className="text-muted2" style={{fontSize: '10px'}}>The header of the in-app pop up containing support information</p>
                                          <input id="supportHeader" name="supportHeader" type="text" className="form-control" value={supportHeader} onChange={this.handleChange} placeholder="NEED SUPPORT?"/>
                                      </div>
                                      <div className="form-group">
                                          <label htmlFor="supportText">Support Body</label>
                                          <p className="text-muted2" style={{fontSize: '10px'}}>The text of the support in-app pop up.</p>
                                          <RichTextEditor id="supportText" name="supportText" toolbarConfig={toolbarConfig} placeholder="Email customerservice@website.mlb.com with your issue and we'll be in touch!" value={supportText} onChange={(text) => this.handleRichTextChange("supportText", text)}/>
                                      </div>
                                  </>
                              }
                              {!isMlbApp &&
                                  <div className="form-group">
                                      <label htmlFor="turnOnSecondMandatoryCheckbox">Second Mandatory Rules & Regs Checkbox</label>
                                      <br/>
                                      <input type="checkbox" checked={turnOnSecondMandatoryCheckbox} id="turnOnSecondMandatoryCheckbox" name="turnOnSecondMandatoryCheckbox" onChange={this.handleChange}/>
                                  </div>
                              }
                              {turnOnSecondMandatoryCheckbox &&
                                  <>
                                      <div className="form-group">
                                          <label htmlFor="secondaryMandatoryCheckboxText">Rules And Regs Text</label>
                                          <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear AFTER the second mandatory confirm rules and regs button on the login form<br/><strong>Example:</strong> Welcome! To play, please confirm that you agree with the second rules and regs</p>
                                          <RichTextEditor
                                              toolbarConfig={toolbarConfig}
                                              id="secondaryMandatoryCheckboxText"
                                              name="secondaryMandatoryCheckboxText"
                                              value={secondaryMandatoryCheckboxText}
                                              onChange={(text) => this.handleRichTextChange("secondaryMandatoryCheckboxText", text)}
                                              placeholder=""
                                          />
                                      </div>
                                      <div className="form-group">
                                          <label htmlFor="didNotCheckRulesAndRegsHeader2">Did Not Check Rules And Regs Header</label>
                                          <p className="text-muted2" style={{fontSize:'10px'}}>The header of the error when the Rules & Regs checkbox isn't error</p>
                                          <input id="didNotCheckRulesAndRegsHeader2" name="didNotCheckRulesAndRegsHeader2" type="text" className="form-control" value={didNotCheckRulesAndRegsHeader2} onChange={this.handleChange} placeholder="Input Error"/>
                                      </div>
                                      <div className="form-group">
                                          <label htmlFor="didNotCheckRulesAndRegsBody2">Did Not Check Rules And Regs Body</label>
                                          <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the body message of the pop up</p>
                                          <RichTextEditor
                                              toolbarConfig={toolbarConfig}
                                              id="didNotCheckRulesAndRegsBody2"
                                              name="didNotCheckRulesAndRegsBody2"
                                              value={didNotCheckRulesAndRegsBody2}
                                              onChange={(text) => this.handleRichTextChange("didNotCheckRulesAndRegsBody2", text)}
                                              placeholder=""
                                          />
                                      </div>
                                  </>
                              }
                          </>
                      }
                  </form>
                </div>
              </div>
              <div className="container-out" style={{width:'45%', float:'right', marginRight:'20px'}}>
                <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                  <p style={{fontSize:'20px', fontWeight:'bold'}}>Why do I need rules & regulations?</p>
                  <p className="text-muted2">While each state, country, and province is different, you will want to make sure you are compliant with all contest rules and laws.  It is your responsibility to update, maintain, & host your rules and regulations tab.</p>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpRulesAndRegs;
